import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_patch_hash=37d6a94fccfe8fb4f8d679512d628c76d89302ef7b906b1f63be0d66ea5ba928_3e1b2a98a4c5361d13ee232acd04bb2a/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_patch_hash=37d6a94fccfe8fb4f8d679512d628c76d89302ef7b906b1f63be0d66ea5ba928_3e1b2a98a4c5361d13ee232acd04bb2a/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_patch_hash=37d6a94fccfe8fb4f8d679512d628c76d89302ef7b906b1f63be0d66ea5ba928_3e1b2a98a4c5361d13ee232acd04bb2a/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_patch_hash=37d6a94fccfe8fb4f8d679512d628c76d89302ef7b906b1f63be0d66ea5ba928_3e1b2a98a4c5361d13ee232acd04bb2a/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_patch_hash=37d6a94fccfe8fb4f8d679512d628c76d89302ef7b906b1f63be0d66ea5ba928_3e1b2a98a4c5361d13ee232acd04bb2a/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_patch_hash=37d6a94fccfe8fb4f8d679512d628c76d89302ef7b906b1f63be0d66ea5ba928_3e1b2a98a4c5361d13ee232acd04bb2a/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_patch_hash=37d6a94fccfe8fb4f8d679512d628c76d89302ef7b906b1f63be0d66ea5ba928_3e1b2a98a4c5361d13ee232acd04bb2a/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_patch_hash=37d6a94fccfe8fb4f8d679512d628c76d89302ef7b906b1f63be0d66ea5ba928_3e1b2a98a4c5361d13ee232acd04bb2a/node_modules/next/dist/client/components/render-from-template-context.js");
